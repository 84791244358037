<template>
    <!-- navbar component here -->
    <div id="navbar">
        <CContainer fluid>
            <CNavbar>
                <CNavbarBrand> <LogoHomeButton /> </CNavbarBrand>
            </CNavbar>
        </CContainer>

        <!-- dropdown menu here -->
        <Button class="dropdown" @click="viewNavbar()"> Releases </Button>
        
        <router-view />
        <suspense>

            <!-- iterate through list of available dates for monthly viewing -->
            <ul id="releases_menu">
                <li v-for="(date, i) in dateList" :key="i" class="releases_item"> 
                    
                    <!-- loads the monthly view page component on click -->
                    <router-link 
                        :to="{ name: 'monthly', params: { month: date.month, year: date.year } }"
                        class="releases_item"
                    >
                        {{ date.month }} {{ date.year }} 
                    </router-link>
                </li>
            </ul>

        </suspense>

    </div>
</template>

<script>
    import LogoHomeButton from '@/components/buttons/LogoHome.vue'
    import database from '../data/updates.json';

    let updates = database.updates;
    let open = false;

    export default {
        name: 'NavBar',
        components: {
            LogoHomeButton,
        },

        computed: {
            // lists all the available dates for monthly views without repetition
            dateList(){
                let dates = [];
                for (let i=0; i<updates.length; i++) {

                    // retrieve date details of update here
                    let exists = 0;
                    let date_year = updates[i].section.year;
                    let date_month = updates[i].section.month;

                    const date_object = { year: date_year, month: date_month }

                    // check the dates for each updates
                    if (dates.length == 0) {
                        dates.push(date_object);
                    } else {
                        // iterate over list of dates in dropdown menu and check if it needs updating
                        for (let j=0; j<dates.length; j++) {

                            // if date already is list of dates to place in dropdown, then skip
                            if (dates[j].year == date_year && dates[j].month == date_month) {
                                exists = 1;
                            }
                        }
                        // add new date to the list of dates available
                        if (exists == 0) { dates.push(date_object); }
                    }
                }
                // console.log(dates)
                return dates;
            },
        },

        methods: {
            // opens and closes navbar dropdown menu on click of it
            viewNavbar() {
                // retrieves id element of the drodpwn menu and changes its css display style
                const navbar_div = document.getElementById("releases_menu")

                // it watches the open element to determine if dropdown needs to close or not
                if (this.open == false) {
                    navbar_div.style.display = "flex";
                    this.open = true;
                } else {
                    navbar_div.style.display = "none";
                    this.open = false;
                }
            },
        },

        data () {
            return {
                navtitle: 'Releases',
                updates: updates,
                open: open
            }
        }
    }
</script>

<style scoped>
@import '../assets/font.css';

#navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    background-color: #008D6C;
    filter: drop-shadow(0.06em 0.08em rgb(0, 0, 0)); /* fallback color */
    filter: drop-shadow(0.06em 0.08em rgb(0, 0, 0, 0.3));

    justify-content: space-between;
    align-items: center;
    z-index:1;
    top: 1;
    height: 3.5em;
    width: 100%;
    /* border: 5px solid yellow; */
}

#releases_menu {
    position: fixed;
    display: none;
    flex-direction: column;
    background-color: #008D6C;

    padding-bottom: 1em;
    min-height: 4em;
    max-height: 7em;
    width: 8em;
    z-index:0;

    right: 0;
    top: 3.5em;

    overflow-y: scroll;
}

.releases_item {
    color: white;
    background-color: transparent;
    border: transparent;
    
    padding-left: .6em;
    list-style-type: none;
}

.releases_item:hover {
    color: black;
    text-decoration: underline;
    cursor: pointer;
}

.dropdown {
    color: white;
    margin-right: 1em;
    background-color: #008D6C;
    border-color: transparent;
    cursor: pointer;
}

/* on hover animations here */

.dropdown:hover {
    color: black;
}

</style>