<template>
    <NavBar />
    <SupportButton />
        <div  id="individualpage">

            <!-- section for title header -->
            <div id="individual_header">
                <div id="title_date">
                    <CRow id="title_contents">

                        <!-- header part -->
                        <CRow class="titillium-web-bold-green" id="update_name">
                            {{ selectedRelease.title }} </CRow>

                        <!-- subtitle part -->
                        <CRow id="update_date">
                            <CRow class="titillium-web-regular-green" id="update_text"> 
                                Date released </CRow>

                            <CRow> <img class ="calendar" id="calendar_img" alt='Calendar' 
                                src="../assets/icons/calendar-alt-g.png"> </CRow>

                            <CRow class="titillium-web-regular-green" id="update_time"> 
                                {{ selectedRelease.section.month }} {{ selectedRelease.section.year }} </CRow>
                        </CRow>

                    </CRow>
                </div>
                <BackButton />
            </div>

            <!-- section for component view -->
            <div class="updated_components" ref="updated_components">
                <div v-for="component in updatedComponents" :key="component.component" class="updated_components">
                    <div v-if="component.release_notes == null && component.updated_services.length > 0" class="updated_components"> 
                        <ComponentServicesCard :component="component" />
                    </div>
                    <div v-else class="updated_components"> 
                        <ComponentNotesCard :component="component" />
                    </div>
                </div>
                <BackButton />
            </div>
            
        </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import ComponentNotesCard from '@/components/ComponentNotesCard.vue'
import ComponentServicesCard from '@/components/ComponentServicesCard.vue'
import SupportButton from '@/components/buttons/Support.vue'
import BackButton from '@/components/buttons/Back.vue'

import database from '../data/updates.json';
let updates = database.updates;

// let selected_release;

  export default {
    name: 'IndividualPage',
    components: {
      NavBar,
      ComponentNotesCard,
      ComponentServicesCard,
      SupportButton,
      BackButton,
    },
    computed: {
        // returns selected update object using id as the filter
        selectedRelease() {
            // need fix: this assumes that update always exists in database

            let selected_id = this.$route.params.id;
            for (let i=0; i<updates.length; i++) {
                if (updates[i].id == selected_id) {
                    // console.log(updates[i]);
                    return updates[i];
                }
            }
            // need catcher
            return {};
        },

        // returns the array of updated components from selected update release
        updatedComponents() {
            return this.selectedRelease.description;
        }
        
    },
    data () {
      return {
        id: this.$route.params.id,
        updates: updates,
      }
    }
  }
</script>

<style scoped>
#individualpage {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    width: 100%;
}

#individual_header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    width: 100%;
    /* border: 5px solid red; */
}

.updated_components {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
    width: 100%;
    /* border: 5px solid blue; */
}

#title_date {
    background-color: #F6F7F7;

    padding: 0.5em;
    border-radius: 2em;
    margin-top: 5em;
    min-height: 5em;
    min-width: 62%;
    max-width: 80%;
    height: auto;
    /* border: 5px solid red; */
}

#title_contents {
    display: flex;
    flex-direction: column;
    /* border: 5px solid yellow; */
}

#update_date {
    display: flex;
    flex-direction: row;
    /* border: 2px solid blue; */
}

#update_text, #update_time {
    font-size: 14px;
}

#update_name {
    font-size: 34px;
    /* border: 2px solid red; */
}

#calendar_img {
    width: 0.6em;
    margin-top: 0.3em;
    margin-right: 0.3em;
    margin-inline: 0.6em;
}

</style>