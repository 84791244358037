
import { createWebHistory, createRouter } from "vue-router"

import HomePage from '@/pages/Home.vue'
import MonthlyPage from '@/pages/Monthly.vue'
import IndividualPage from '@/pages/Individual.vue'

const routes = [
  { path: '/', 
    component: HomePage 
  },
  // always assumes that there will be data passed to these components
  { path: '/monthly/:month/:year', 
    name: 'monthly',
    component: MonthlyPage, 
    props: true,
  },
  { path: '/release/:id', 
    name: 'release',
    component: IndividualPage,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;