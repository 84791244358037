<template>
    <!-- this button opens a new window for release notes -->

    <button id="component_notes_button" @click="openNotesLink(release_url)">
        <div class="titillium-web-regular-white" id="component_button_text">
            {{ button_type }} </div>
    </button>
</template>

<script>
    let button_type = "Release notes";

    export default {
        name: 'ShowReleaseNotesButton',
        methods: {
            // opens release notes window
            openNotesLink(url) {
                window.open(url, '_blank');
            }
        },
        props: { release_url: String, },
        data () {
            return { button_type: button_type, }
        }
    }
</script>

<style scoped>

#component_notes_button {
    background-color:#008D6C;
    border: transparent;

    margin-block: 0.6em;
    padding: 0.6em;
    padding-inline: 1.5em;
    border-radius: 1.5em;
    
    font-size: 0.9em;
}

/* on hover animations here */

#component_notes_button:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0.1em 0.1em #008D6C;
    cursor: pointer;
}

#component_notes_button:hover > #component_button_text {
    color: #008D6C;
}
</style>