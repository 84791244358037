<template>
    <!-- this button navigates to monthly page view -->
    <router-view />
    <suspense>

        <!-- pass selected date details to monthly view page and load new page -->
        <router-link 
            :to="{ name: 'monthly', params: { month: this.month, year: this.year } }"
            style="text-decoration: none">
        
            <button id="date_button">

                <!-- calendar icon -->
                <CRow> <img class ="calendar" id="calendar_img" alt='Calendar' 
                    src="../../assets/icons/calendar-alt.png"> </CRow>

                <!-- text -->
                <CRow class="titillium-web-semibold-black" id="date_text">
                    {{ month + " " + year }} </CRow>

            </button>
        </router-link>

    </suspense>
</template>

<script>
    export default {
        name: 'SeeMonthlyTextButton',
        props: {
            month: String,
            year: String
        },
    }
</script>

<style scoped>

#date_button {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    border: transparent;
    /* border: 1px solid red; */
}

#calendar_img {
    width: 0.8em;
    margin-top: 0.5em;
    margin-right: 0.5em;
}

/* on hover animations here */

#date_button:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>