<template>
    <!-- this button navigates back to the previously opened route -->
     
    <button id="back_button" @click="returnBack()">

        <!-- CSS design of an arrow -->
        <div id="arrow-left"></div>

        <div class="titillium-web-semibold-white" id="back_text">
            {{ button_text }} </div>

    </button>
</template>

<script>
  // edit the text displayed in button here
  let button_text = "Back";

  export default {
    name: 'BackButton',
    methods: {
        // returns one page back
        returnBack() {
            this.$router.back();
        }
    },  
    data () {
        return { button_text: button_text, }
    }
  }
</script>

<style scoped>

#back_button {
  display: flex;
  flex-direction: row;
  background-color:#008D6C;

  margin: 1em;
  margin-bottom: 2em;
  padding: 0.4em;
  padding-inline: 1em;
  border-radius: 0.8em;
  border: transparent;

  font-size: 0.9em;
}

#arrow-left {
  margin-right: 0.4em;
  border-top: 0.35em solid transparent;
  border-bottom: 0.35em solid transparent;
  border-right: 0.35em solid #F4FFE0;
}

#back_text {
  font-size: 14px;
}

/* on hover animations here */

#back_button:hover {
  background-color:#F4FFE0;
  color: #1E1E1E;
  cursor: pointer;
}

#back_button:hover > #arrow-left {
  border-right: 0.4em solid #1E1E1E;
}

#back_button:hover > #back_text {
  color: #1E1E1E;
}
</style>