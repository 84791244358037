<template>
      <NavBar />
      <SupportButton />

      <div id="monthlypage">
        <HeaderBar />

        <!-- date header -->
        <div id="content_holder">
          <CRow class="titillium-web-semibold-white" id="monthly_title">
            {{ updatedParams.month }} {{ updatedParams.year }} </CRow>
        </div>

        <!-- loop through updates database and displays updates of selected month and year -->
        <div v-for="(update,i) in releasesList" :key="i" class="monthly_content" >

          <!-- creates a new card component for the update -->
          <ReleaseCardNoDate
            :id="update.id"                 
            :title="update.title"
            :month="update.section.month"
            :year="update.section.year"
            :components="update.description" />
        </div>

        <BackButton />
      </div>
</template>
  
<script>
  import NavBar from '@/components/NavBar.vue'
  import HeaderBar from '@/components/Header.vue'
  import ReleaseCardNoDate from '@/components/ReleaseCardNoDate.vue'
  import SupportButton from '@/components/buttons/Support.vue'
  import BackButton from '@/components/buttons/Back.vue'

  import database from '../data/updates.json';
  let updates = database.updates;

  // values for the selected releases
  let selected_month;
  let selected_year;
  let selected_releases = [];
  
  export default {
    name: 'MonthlyPage',
    components: {
      NavBar,
      HeaderBar,
      ReleaseCardNoDate,
      SupportButton,
      BackButton,
    },

    computed: {
      // returns updated date details of which releases should be displayed
      updatedParams() {
        selected_month = this.$route.params.month;
        selected_year = this.$route.params.year;
        return { month: selected_month, year: selected_year }
      },

      // returns an array of releases to display
      releasesList() {
        selected_releases = [];
        for (let i=0; i<updates.length; i++) {
          
          // check all updates if it matches the date being asked for
          let check_month = updates[i].section.month;
          let check_year = updates[i].section.year;

          if (check_month == this.updatedParams.month && check_year == this.updatedParams.year) {
            selected_releases.push(updates[i])
          }
        }
        // console.log(selected_releases)
        return selected_releases;
      }
    },

    data () {
      return {
          selected_month: selected_month,
          selected_year: selected_year,
          updates: updates,
      }
    }
  }
</script>

<style scoped>

#monthlypage {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  width: 100%;
}

#content_holder {
  display: flex;
  flex-direction: column;
  background-color: #008D6C;
  
  padding-block: 0.5em;
  margin-bottom: 1em;
  width: 100%;
  height: auto;
  /* border: 5px solid yellow; */
}

#monthly_title {
  font-size: 34px;
  letter-spacing: 0.03em;
  /* border: 5px solid red; */
}

.monthly_content{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  /* border: 5px solid yellow; */
}
</style>