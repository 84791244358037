<template>
    <div id="headerbar">
        <div class="header_child" id="colored">
            <!-- Gray background colored div --> </div>

        <div class="header_child" id="contents">
            <CColumn> 
                <img class ="logo" alt='EBS Logo' src="../assets/logos/logo.png">
            </CColumn>

            <!-- header title -->
            <CColumn class="header_title">
                <CRow class="titillium-web-regular-white">
                    <div id="name_holder">
                        Enterprise Breeding System
                    </div>
                </CRow>

                <!-- header text content -->
                <CRow class="titillium-web-bold-green" id="title">
                    {{ title }}</CRow>
                <CRow class="titillium-web-regular-green">
                    {{ subtitle1 }}</CRow>
                <CRow class="titillium-web-regular-green">
                    {{ subtitle2 }}</CRow>
            </CColumn>

        </div>

    </div>
</template>


<script>
    export default {
        name: 'HeaderBar',
        data () {
            return {
                title: 'Release updates',
                subtitle1: 'This page provides information on which EBS components',
                subtitle2: 'has updates in each release.',
            }
        }
    }
</script>

<style scoped>
@import '../assets/font.css';

#headerbar {
    position: relative;
    display: table;
    justify-content: center;

    margin-top: 5em;
    height: 18em;
    width: 100%;
    overflow: auto;
    /* border: 4px solid red; */
}

.header_child {
    position: table-row;
    height: auto;
}

#contents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: transparent;

    justify-content: center;
    margin-bottom: 1.5em;
    /* border: 4px solid blue; */
}

#colored {
    position: absolute;
    background-color: #F6F7F7;

    border-radius: 2em;
    margin-top: 3.5em;
    margin-inline: 2em;
    height: 12em;
    z-index: -1;
    left: 0;
    right: 0;
}

.header_title {
    display: flex;
    padding-left: 1em;
    padding-right: 1em;
    flex-direction: column;
    align-items: flex-start;
}

#name_holder {
    background-color: #008D6C;
    border-radius: 2em;
    padding-inline: 1.5em; 
    padding-block: 0.4em;
}

#title {
    font-size: 52px;
}

/* Animated CSS:
- https://codepen.io/wvyeun/pen/QRNwGW
- https://stackoverflow.com/questions/27123399/how-to-move-an-image-up-and-down-smoothly-with-css
*/

.logo {
    width: 12em;
    padding: 0.5em;
    -webkit-animation: mover 1.5s infinite alternate;
    animation: mover 1.5s infinite alternate;
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
</style>