<template>
    <!-- this button navigates to individual release update page-->
    <router-view />

    <!-- pass id of update to individual release page -->
    <router-link 
        :to="{ name: 'release', params: { id: this.id } }" 
        style="text-decoration: none">

        <button class="titillium-web-semibold-green" id="more_button">
            {{ see_more_text }} </button>

    </router-link>
</template>

<script>
    // edit see more text button here
    let see_more_text = 'See more...';

    export default {
        name: 'SeeMoreTextButton',
        props: { id: String, },
        data () {
            return { see_more_text: see_more_text, }
        }
    }
</script>

<style scoped>

#more_button {
    margin-top: 0.8em;
    background-color: transparent;
    border: transparent;
}

/* on hover animations here */

#more_button:hover {
    color: #1E1E1E;
    cursor: pointer;
}
</style>