<template>
    <!-- this button routes back to home page -->

    <router-view />
    <router-link to="/" style="text-decoration: none">

        <button id="logo_home_buttom">
            <!-- logo file -->
            <img id="logo" alt='EBS Logo' 
                src="../../assets/logos/logo.png">
            
            <!-- name to display -->
            <div class="titillium-web-bold-white" id="nav_name">
                {{ title }} </div>
        </button>

    </router-link>
</template>

<script>
    // edit the name to display here 
    let logo_title = 'EBS Official Releases'

    export default {
        name: "LogoHomeButton",
        data () { 
            return { title: logo_title, }
        },
    }
</script>

<style scoped>

#logo_home_buttom {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    border: transparent;

    align-items: center;
    margin-left: 1em;
}

#nav_name {
    letter-spacing: 0.03em;
}

#logo {
    height: 2em;
    margin-right: 0.5em;
    filter: drop-shadow(0.06em 0.08em rgb(0, 0, 0)); /* fallback color */
    filter: drop-shadow(0.06em 0.08em rgb(0, 0, 0, 0.3));
}

/* on hover animations here */

#logo_home_buttom:hover {
    cursor: pointer;
}
</style>