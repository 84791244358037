<template>
    <!-- this button opens a new window for the support portal -->

    <div id="support_parent">

        <button id="support_circle" @click="openLink()">
            <div class="titillium-web-bold-white" id="support_text">
                {{ support_text }} </div>
        </button>

    </div>
</template>

<script>
    // edit link of support portal page here
    let support_portal = 'https://ebsproject.atlassian.net/servicedesk/customer/portals';
    let support_text = '?';

    export default {
        name: 'SupportButton',
        methods: {
            // opens a new window
            openLink() {
                window.open(support_portal, '_blank');
            },
        },
        data () {
            return { support_text: support_text, }
        }
    }
</script>

<style scoped>

#support_parent {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;

    width: 100%;
    height: 100%;
}

#support_circle {
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: #008D6C;
    border: 0.3em solid #FFFFFF;

    border-radius: 50%;
    margin: 1.5em;
    width: 4.5em;
    height: 4.5em;
    
    bottom: 0
}

#support_text {
    font-size: 3em;
}

/* on hover animations here */

#support_circle:hover {
    background-color: #FFFFFF;
    border: 0.3em solid #008D6C;
    cursor: pointer;
}

#support_circle:hover > #support_text {
    color: #008D6C;
}
</style>