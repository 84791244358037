<template>
  <router-view />
</template>

<script>
import './assets/font.css';
export default { name: 'App', }
</script>

<!-- base format, applied to all elements with no specific styles -->
<style>
* {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  align-items: center;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
</style>