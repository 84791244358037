<template>
    <div id="component_services_card">

        <CColumn>
            <!-- updated component name -->
            <CRow class="titillium-web-semibold-black">
                {{ component.domain }} {{ component.component }} </CRow>

            <!-- updated component content -->
            <CRow id="component_services_updates">

                <ul class="titillium-web-regular-black" >
                    <li class="list_items">
                        {{ component.content }}</li>

                    <!-- services updated section -->
                    <li class="list_items" id="updated_cssm_services">
                        <CColumn>
                            <CRow>Services updated</CRow>

                            <button id="imports_map" @click="openImportsMap()"> 
                                (JSON file import maps)</button>
                        </CColumn>
                        
                        <!-- list all services from component list of services -->
                        <ul id="cssm_services_list">
                            <li v-for="service in component.updated_services" :key="service.service">
                                <button @click="openServiceWindow(service.service_url)" id="service_button">
                                    {{ service.service }} 
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </CRow>
            
        </CColumn>
        
    </div>
</template>

<script>
    export default {
        name: 'ComponentServicesCard',
        props: {
            component: Object,
        },
        methods: {
            openImportsMap() {
                window.open(this.import_maps, '_blank');
            },
            openServiceWindow(url) {
                window.open(url, '_blank');
            }
        },
    }
</script>

<style scoped>

#component_services_card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F6F7F7;

    min-height: 6em;
    height: auto;
    width: 82%;
    margin: 0.4em;
    border-radius: 2em;
    padding-block: 1em;
    padding-inline: 2em;

    /* border: 5px solid blue; */
}

#component_services_updates {
    display: flex;
    margin-top: 0.2em;
    margin-left: 1em;
}

#updated_cssm_services {
    display: list-item;
}

.list_items {
    font-size: 0.95em;
}

#cssm_services_list {
    /* color: #008D6C; */
    margin-left: 1em;
}

#imports_map {
    padding-inline: 0.2em;
    background-color: transparent;
    border: transparent;
    color: #008D6C;
}

#service_button {
    color: #008D6C;
    background-color: transparent;
    border: transparent;
}

/* on hover animations here */

#imports_map:hover {
    text-decoration: underline;
    cursor: pointer;
}



#service_button:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>